<template>

    <div class="d-lg-none">

        <div v-if="loading">
            <div class="card bg-info mt-4">
                <loading-animation/>
                Please wait while loading data from the server.
            </div>
        </div>

        <div v-else-if="error">
            <div class="card bg-danger mt-4">
                <error-icon/>
                Failed to load data from the server.
            </div>
        </div>

        <div v-else>

            <div v-for="(i, k) in details.data" v-bind:key="k" class="mt-4">
                <div class="gr-3-cols">
                    <div class="fl-gr-1 b-1 py-2">
                        <div class="text-center">
                            <div class="pos-r ">
                                Sl No
                            </div>
                        </div>
                    </div>
                    <div class="fl-gr-1 b-1 py-2">
                        <div class="text-center">
                            <div class="pos-r">
                                Emp ID
                            </div>
                        </div>
                    </div>
                    <div class="fl-gr-1 b-1 py-2">
                        <div class="text-center">
                            <div class="pos-r">
                                Name
                            </div>
                        </div>
                    </div>

                </div>
                <div class="b-1 bs-3">
                    <div class="gr-3-cols font-inter-medium">

                        <div class="py-2 b-1">
                            <div class="text-center">
                                <div class="pos-r ">
                                    {{ k + 1 }}
                                </div>
                            </div>
                        </div>
                        <div class="py-2 b-1">
                            <div class="text-center">
                                <div class="pos-r ">
                                    {{ i.employee_number }}
                                </div>
                            </div>
                        </div>
                        <div class="py-2 b-1">
                            <div class="text-center">
                                <div class="pos-r ">
                                    {{ i.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row b-1 fs--1 m-0">
                <span class="b-1 w-100 col-12 py-2">
                    <div class="p-1">
                        Email  &nbsp; &nbsp;: <span class="ml-2">{{ i.email }}</span>
                    </div>
                </span>
                        <span class="b-1 col-12 py-2">
                    <div class="p-1">
                         Phone : <span class="ml-1">
                        {{ i.phone }}
                    </span>
                    </div>
                </span>
                        <span class="b-1 col-6 py-2">
                    <div class="p-1">
                         Auction Created :<span class="ml-2">
                        {{ i.auction_created }}
                    </span>
                    </div>
                </span>
                        <span class="b-1 col-6 py-2">
                    <div class="p-1 fl-x fl-a-c">
                         Status <span class="ml-2">
                        <activate-suspend-buttons :active="i.is_active"
                                                  @activate="setIsActive(i, true)"
                                                  @suspend="setIsActive(i, false)">
                        </activate-suspend-buttons>
                    </span>
                    </div>
                </span>

                        <span class="b-1 col-12 py-2">
                    <div class="p-1 fl-x fl-j-c py-3">
                        <div class="btn-group">
                            <btn v-if="canView" design="" shadow="0" icon="fa fa-eye"
                                 class="border-gray-hover mu-p p-2 text-5"
                                 @click="setView(i)"></btn>
                            <btn v-if="canEdit" design="" shadow="0" icon="fa fa-pencil"
                                 class="border-gray-hover mu-p p-2 text-5"
                                 @click="setEdit(i)"></btn>
                            <btn v-if="canDelete" design="" shadow="0" icon="fa fa-trash-o"
                                 class="border-gray-hover mu-p p-2 text-5"
                                 @click="deleteItem(i)"></btn>
                        </div>
                    </div>
                </span>
                    </div>

                </div>
            </div>
            <div v-if="details.data.length !== 0" class="mt-3 fl-te-c-res">
                <div class="mb-2">
                    Showing {{ details.from }} to {{ details.to }} of {{ details.total }} entries
                </div>
                <div>
                    <btn class="m-1" size="sm" @click="prevClicked" v-if="prev" type="button"
                         icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" type="button">{{ details.current_page }}</btn>
                    <btn class="m-1" size="sm" @click="nextClicked" v-if="next" type="button"
                         icon="fa fa-chevron-right"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-right"/>
                </div>
            </div>

            <div v-if="details.data.length === 0" class="card">
                <div class="mb-2">
                    <div>
                        <div class="fl-x fl-j-c fl-a-c">
                            No Data
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'secure-axios';
import urls from '../../data/urls';
import ActivateSuspendButtons from '@components/manage-users/ui/ActivateSuspendButtons';
import _ from 'lodash';

export default {
    name : 'UsersListMobileView',

    props : {
        userList : {
            type    : Boolean,
            default : false
        },
        role : {
            type : String
        },
        status : {
            type : String
        },

        ext_search : {
            type : String
        }
    },

    watch : {
        role () {
            this.timeOutLoadData();
        },
        status () {
            this.timeOutLoadData();
        },
        ext_search () {
            this.timeOutLoadData();
        }
    },

    components : {
        ActivateSuspendButtons
    },

    data () {
        return {
            prev     : '',
            next     : '',
            page     : '1',
            per_page : '10',
            loading  : true,
            error    : false,
            details  : null
        };
    },

    computed : {
        ...mapGetters(['currentUser']),
        canView () {
            return (i) => {
                return this.currentUser.is_superuser ||
                    this.currentUser.is_ventura_admin ||
                    this.currentUser.is_bank_admin ||
                    this.currentUser.is_bank_user;
            };
        },
        canEdit () {
            return (i) => {
                return this.currentUser.is_superuser ||
                    this.currentUser.is_ventura_admin ||
                    this.currentUser.is_bank_admin ||
                    this.currentUser.is_bank_user ||
                    this.currentUser.permission.can_edit_bank_admin;
            };
        },
        canDelete () {
            return (i) => {
                return this.currentUser.is_superuser ||
                    this.currentUser.is_ventura_admin ||
                    this.currentUser.is_bank_admin ||
                    this.currentUser.is_bank_user ||
                    this.currentUser.permission.can_delete_bank_admin;
            };
        }
    },

    mounted () {
        this.loadData();
    },

    methods : {
        timeOutLoadData : _.debounce(function () {
            this.loadData();
        }, 2000),
        loadData () {
            const that = this;

            that.loading = true;
            that.error = false;

            let dataUrl;
            dataUrl = urls.userList.bankAdmin.bankUserList;

            if (this.userList) {
                dataUrl = urls.userList.list;
            }

            axios.get(dataUrl, {
                params : {
                    page       : that.page,
                    per_page   : that.per_page,
                    role       : that.role,
                    status     : that.status,
                    ext_search : that.ext_search,
                    bank       : that.$route.params.id
                }
            }).then(function (response) {
                const json = response.data;
                that.details = { ...json };
                that.loading = false;
                that.error = false;
                that.setPagination();
            }).catch(function (exception) {
                console.log('exception : ', exception);
                that.loading = false;
                that.error = true;
            });
        },
        setPagination () {
            this.prev = parseInt(this.details.current_page) !== this.details.from;
            this.next = parseInt(this.details.current_page) !== this.details.last_page;
        },
        prevClicked () {
            this.page = parseInt(this.page) - 1;
            this.loadData();
        },
        nextClicked () {
            this.page = parseInt(this.page) + 1;
            this.loadData();
        },

        setView (item) {
            this.$emit('view', item);
        },

        setEdit (item) {
            this.$emit('edit', item);
        },

        setBankUserEdit (item) {
            this.$emit('editBankUser', item);
        },

        deleteItem (item) {
            this.$emit('delete', item);
        },

        toggleUserActiveStatus (i) {
            this.items[i].active = !this.items[i].active;
        },

        setIsActive (user, status) {
            user.loading = true;
            const that = this;

            const permission = this.checkPermission(status);

            if (!permission) {
                alert('Don\'t have permission for this Action..!');
                return;
            }

            const model = {
                user   : user.id,
                status : status
            };

            axios.form(urls.userList.statusUpdate, model).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    user.loading = false;
                    user.is_active = status;
                    that.$notify(json.message, 'Success', { type : 'success' });
                } else {
                    user.loading = false;
                    user.is_active = !status;
                    that.$notify(json.message, 'Warning', { type : 'warning' });
                }
            }).catch(function (exception) {
                that.$notify('Server Error', 'Error', { type : 'danger' });
                console.log('exception occurred : ', exception);
                user.is_active = !status;
            });
        },
        checkPermission (status) {
            if (status === true) {
                if (this.currentUser.is_superuser) {
                    return true;
                } else {
                    return this.currentUser.permission.can_approve_bank_admin;
                }
            } else {
                if (this.currentUser.is_superuser) {
                    return true;
                } else {
                    return this.currentUser.permission.can_reject_bank_admin;
                }
            }
        }

    }
};
</script>

<style scoped lang="scss">
.gr-4-cols {
    display: grid;
    grid-template-columns:repeat(4, 1fr);
}

.gr-3-cols {
    display: grid;
    grid-template-columns:repeat(3, 1fr);
}

.c-line-bottom {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 1px;
        width: 100%;
        background-color: rgba(128, 128, 128, 0.3);
    }
}

.icon-danger {
    i {
        color: var(--color-danger) !important;
    }
}

.icon-primary {
    i {
        color: var(--color-primary) !important;
    }
}
</style>
