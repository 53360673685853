<template>
    <div>

        <s-form class="row" @submit="onSubmit" ref="formValidator">

            <div class="col-6">
                <validated-input label="Username*" name="Username" placeholder="Username" class="c-form-input"
                                 v-model="model.username" :rules="{required:true}"
                                 @blur="checkUsername(model.username)"/>
            </div>

            <div class="col-6">
                <validated-input label="Employee ID*" placeholder="Example: ENP576281" class="c-form-input"
                                 v-model="model.employee_number" name="Employee ID"
                                 :rules="{required:true}"/>
            </div>

            <div class="col-6">
                <validated-input label="First Name*" placeholder="First Name" class="c-form-input"
                                 v-model="model.first_name" name="First Name"
                                 :rules="{required:true}"/>
            </div>

            <div class="col-6">
                <validated-input label="Last Name*" placeholder="Last Name" class="c-form-input"
                                 v-model="model.last_name" name="Last Name"
                                 :rules="{required:true}"/>
            </div>

            <div class="col-6">
                <validated-input label="Mobile Number*" placeholder="Mobile number" class="c-form-input"
                                 v-model="model.mobile_number" type="number" name="Mobile number"
                                 :rules="{required:true, min:10, max:10}" @blur="checkMobile(model.mobile_number)"/>
            </div>

            <div class="col-6">
                <validated-input label="E-Mail ID*" placeholder="E-Mail ID" type="email"
                                 class="c-form-input" name="email" @input="customEmailValidate(model.email)"
                                 v-model="model.email" @blur="checkEmailExists(model.email)"
                                 :rules="{required:rules.email}"/>
                <p class="text-danger" v-if="invalidEmail && model.email">Entered domain address is not accepted.</p>
            </div>

            <div class="col-12">
                <div class="fl-x fl-j-c">
                    <btn text="Cancel" type="button" class="px-5" @click="closeEditModal"
                         design="basic-b" color="secondary"></btn>

                    <btn text="Update" loading-text="Updating..." :loading="loading" class="px-5 ml-3" design="basic-a"
                         color="secondary"></btn>
                </div>
            </div>

        </s-form>

    </div>
</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name : 'EditBankAdmin',

    props : { initialData : { type : Object } },

    data () {
        return {
            loading      : false,
            invalidEmail : false,
            rules        : {
                email : {
                    required : true
                }
            },
            model : {
                username        : '',
                first_name      : '',
                last_name       : '',
                employee_role   : '',
                employee_number : '',
                mobile_number   : '',
                email           : '',
                bank            : ''
            },
            addUrl : urls.userList.venturaAdmin.addEdit,

            userId : ''
        };
    },

    mounted () {
        this.setData();
    },

    methods : {
        setData () {
            this.model = { ...this.initialData };
        },

        async onSubmit () {
            if (this.invalidEmail) {
                return false;
            }
            const formValidator = this.$refs.formValidator;
            this.loading = true;
            const response = await axios.form(urls.userList.bankAdmin.addEdit, this.model).catch(function (exception) {
                this.loading = false;
            });
            if (response.data.success) {
                this.loading = false;
                this.userId = response.data.id;
                this.$emit('success');
            } else {
                formValidator.setErrors(response.data.errors);
                this.errorMessage = response.data.errors.__all__ || '';
                this.loading = false;
            }
        },

        formSuccess () {
            this.$notify('Successfully Updated the user..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        },

        checkUsername (username) {
            const that = this;
            const data = {
                username : username
            };

            axios.form(urls.userList.usernameCheck, data).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                } else {
                    that.$refs.formValidator.setErrors({ Username : json.message });
                }
            }).catch(function (exception) {
                console.log('exception occurred during the checking for username availability : ', exception);
            });
        },

        customEmailValidate (value) {
            const allowedDomains = ['yahoo.com', 'gmail.com', 'outlook.com', 'hotmail.com'];
            const domain = value.split('@')[1];
            this.invalidEmail = allowedDomains.includes(domain);
        },

        checkEmailExists (email) {
            const that = this;
            const data = {
                email : email
            };
            axios.form(urls.userList.emailCheck, data).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                } else {
                    if (!that.invalidEmail) {
                        that.$refs.formValidator.setErrors({ email : json.message });
                    }
                }
            }).catch(function (exception) {
                console.log('exception occurred during the checking for username availability : ', exception);
            });
        },

        checkMobile (mobile) {
            const that = this;
            const data = {
                mobile : mobile
            };

            axios.form(urls.userList.mobileCheck, data).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                } else {
                    that.$refs.formValidator.setErrors({ 'Mobile number' : json.message });
                }
            }).catch(function (exception) {
                console.log('exception occurred during the checking for username availability : ', exception);
            });
        },

        closeEditModal () {
            this.$emit('closeModal');
        }
    }
};
</script>

<style scoped>

</style>
