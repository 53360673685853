<template>
    <transition name="zoom"
                enter-active-class="animated zoom-up"
                leave-active-class="animated zoom-down" mode="out-in">

        <button v-if="active" @click="$emit('suspend')" key="on-state" class="cursor-pointer border-none bg-2">
            <span class="text-success"><i class="fa fa-toggle-on fa-2x"></i></span>
        </button>

        <button v-else @click="$emit('activate')" key="off-state" class="cursor-pointer border-none bg-2">
            <span class="text-success"><i class="fa fa-toggle-off fa-2x"></i></span>
        </button>

    </transition>
</template>

<script>
export default {
    name  : 'ActivateSuspendButtons',
    props : {
        active : {
            type    : Boolean,
            default : false
        }
    }
};
</script>

<style scoped>

</style>
