import { render, staticRenderFns } from "./EditBankAdmin.vue?vue&type=template&id=ea70ed82&scoped=true&"
import script from "./EditBankAdmin.vue?vue&type=script&lang=js&"
export * from "./EditBankAdmin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea70ed82",
  null
  
)

export default component.exports