<template>
    <div>
        <div v-if="loading">
            <div class="card bg-info mt-4">
                <loading-animation/>
                Please wait while loading data from the server.
            </div>
        </div>

        <div v-else-if="error">
            <div class="card bg-danger mt-4">
                <error-icon/>
                Failed to load data from the server.
            </div>
        </div>

        <div v-else>
            <inner-page-card route-path="/manage-users/0/" :link-back="true"
                             :heading="bankData.bank_name + ' Users'" icon="fa fa-user">
                <s-form class="font-inter-medium fl-te-c">
                    <div class="d-lg-none-sm-flex pos-r fl-x">
                        <img :src="bankData.bank_logo" class="bank-logo border-r-1" alt="#BankLogo">
                        <p class="font-inter-medium m-0 ml-3 fs--2 capitalize-string">{{ ' ' + bankData.bank_name }}</p>
                    </div>
                    <div class="d-sm-none">
                        <img :src="bankData.bank_logo" class="bank-logo border-r-1" alt="#BankLogo">
                        <p class="font-inter-medium m-0 ml-3 fs--2 capitalize-string">{{ ' ' + bankData.bank_name }}</p>
                    </div>
                    <div v-if="bankData.status === 'Active'">
                        <router-link :to="'/bank-admin/' + bankData.id +  '/add-user/'"
                                     v-if="currentUser.is_superuser || currentUser.is_ventura_admin || currentUser.permission.can_add_bank_admin">
                            <btn class="ml-3" text="Add User" type="submit" size="" color="secondary"></btn>
                        </router-link>
                    </div>

                </s-form>
                <custom-vue-table @pagination-updated="setTotal" class="d-sm-none c-vue-table" :fields="fields"
                                  :url="listUrl" :per-page="10" ref="table" :show-search-box="false" :extra-params="{
                                status: filter.status, role:filter.role, ext_search:filter.ext_search}">

                    <template slot="sl_no" slot-scope="props">
                        {{ props.rowIndex + 1 }}
                    </template>

                    <template slot="name" slot-scope="props">
                        <div v-if="props.rowData.is_bank_admin">
                            <img src="../../../assets/web/logos/fa_user_svg.svg" width="10" alt="" class="mr-2">
                            {{ props.rowData.name }}
                        </div>
                        <div v-else>
                            {{ props.rowData.name }}
                        </div>
                    </template>

                    <template slot="status" slot-scope="props">
                        <activate-suspend-buttons :active="props.rowData.is_active"
                                                  @activate="setIsActive(props.rowData, true)"
                                                  @suspend="setIsActive(props.rowData, false)">
                        </activate-suspend-buttons>
                    </template>

                    <template slot="actions" slot-scope="props">
                        <div class="btn-group-tight">
                            <btn design="" icon="fa fa-eye" class="border-gray-hover p-2 text-5"
                                 @click="setView(props.rowData)"></btn>

                            <btn design="" icon="fa fa-pencil" class="border-gray-hover p-2 text-5"
                                 v-if="(currentUser.is_superuser || currentUser.is_ventura_admin ||
                                 currentUser.permission.can_add_bank_admin) && !props.rowData.is_bank_user"
                                 @click="setEdit(props.rowData)"/>

                            <btn design="" icon="fa fa-pencil" class="border-gray-hover p-2 text-5"
                                 @click="setBankUserEdit(props.rowData)"
                                 v-if="(currentUser.is_superuser || currentUser.is_ventura_admin ||
                                 currentUser.is_bank_admin || currentUser.permission.can_edit_bank_user)
                                  && props.rowData.is_bank_user"/>

                            <btn design="" icon="fa fa-trash-o" class="border-gray-hover p-2 text-5"
                                 @click="deleteItem(props.rowData)"
                                 v-if="currentUser.is_superuser || currentUser.is_ventura_admin
                                  || currentUser.permission.can_delete_bank_admin"></btn>
                        </div>
                    </template>

                </custom-vue-table>

                <modal title="Bank Admin Update" ref="bankAdminEditModal" :no-close-on-backdrop="true" width="60r"
                       header-color="primary">
                    <edit-bank-admin @success="formSuccess" @closeModal="$refs.bankAdminEditModal.close()"
                                     :initial-data="editingItem"/>
                </modal>

                <modal title="Bank User Update" ref="UserEditModal" :no-close-on-backdrop="true" width="60r"
                       header-color="primary">
                    <edit-user :initial-data="editingItem" @success="formSuccess"/>
                </modal>

                <delete-modal ref="userDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                    <p>You are about to delete the User
                        <b v-html="deletingItem && deletingItem.name"></b>. Are you sure?
                    </p>
                    <template #loading>
                        <loading-animation/>
                        Please wait while we delete the user <b v-html="deletingItem && deletingItem.name"></b>.
                    </template>
                </delete-modal>

                <users-list-mobile-view class="d-lg-none" @view="setView" :role="filter.role"
                                        @edit="setEdit" :ext_search="filter.ext_search"
                                        @editBankUser="setBankUserEdit" :status="filter.status"
                                        @delete="deleteItem" ref="userListMobileView"/>

            </inner-page-card>
        </div>
    </div>
</template>

<script>
import UsersListMobileView from '../../../views/users/UsersListMobileView';
import ActivateSuspendButtons from '@components/manage-users/ui/ActivateSuspendButtons';
import urls from '@/data/urls';
import axios from 'secure-axios';
import { mapGetters } from 'vuex';
import EditUser from '../EditUser';
import EditBankAdmin from './EditBankAdmin';

export default {
    name : 'BankAdminUserList',

    components : {
        EditBankAdmin,
        EditUser,
        UsersListMobileView,
        ActivateSuspendButtons
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    data () {
        return {
            totalUsers   : 0,
            deleteUrl    : urls.userList.delete,
            deletingItem : null,
            editingItem  : null,

            listUrl : urls.userList.bankAdmin.bankUserList + '?bank=' + this.$route.params.id,

            filter : {
                status     : '',
                role       : '',
                ext_search : ''
            },

            statusOptions : [
                { label : 'All', value : 'All' },
                { label : 'Active', value : 'True' },
                { label : 'Inactive', value : 'False' }
            ],

            roleOptions : [
                { label : 'All', value : 'All' },
                { label : 'Bank Admin', value : 'bank_admin' },
                { label : 'Bank User', value : 'bank_user' }
            ],

            fields : [
                {
                    name      : '__slot:sl_no',
                    dataClass : 'aligned text-center',
                    title     : 'Sl. No'
                },
                {
                    name      : 'employee_number',
                    dataClass : 'aligned text-center',
                    title     : 'Emp ID'
                },
                {
                    name      : '__slot:name',
                    dataClass : 'aligned text-center',
                    title     : 'Name'
                },
                {
                    name      : 'email',
                    dataClass : 'aligned text-center',
                    title     : 'Email'
                },
                {
                    name      : 'mobile_number',
                    dataClass : 'aligned text-center',
                    title     : 'Phone'
                },
                {
                    name      : 'auction_created',
                    dataClass : 'aligned text-center',
                    title     : 'Auction Created'
                },
                {
                    name      : '__slot:status',
                    dataClass : 'aligned text-center',
                    title     : 'Status'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right',
                    title      : 'Actions'
                }
            ],

            loading : true,
            error   : false,

            bankData : null
        };
    },

    mounted () {
        this.loadDetails();
    },

    methods : {
        setTotal (data) {
            this.totalUsers = data.total;
            console.log('total', this.total);
        },
        loadDetails () {
            const that = this;
            that.loading = true;
            that.error = false;
            axios.form(urls.userList.bankAdmin.bankDataFetch, { id : this.$route.params.id }).then(function (response) {
                that.bankData = { ...response.data.data };
                that.loading = false;
            }).catch(function (exception) {
                that.loading = false;
                that.error = true;
                console.log('exception occurred during the table loading : ', exception);
            });
        },

        setView (item) {
            this.$router.push('/user/' + item.id + '/details/');
        },

        formSuccess () {
            this.$refs.bankAdminEditModal.close();
            this.$refs.UserEditModal.close();
            this.editingItem = null;
            this.$refs.table.refreshTable();
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.bankAdminEditModal.show();
        },

        setBankUserEdit (item) {
            this.editingItem = { ...item };
            this.$refs.UserEditModal.show();
        },

        deleteItem (item) {
            this.deletingItem = { ...item };
            this.$refs.userDeleteModal.show();
        },

        deleteComplete (response) {
            this.$refs.userListMobileView.loadData();
            this.$refs.userDeleteModal.close();
            if (response.error === false) {
                this.$notify('Successfully deleted', 'success',
                    {
                        type      : 'success',
                        duration  : 3000,
                        permanent : false
                    });
                this.$refs.table.refreshTable();
            } else {
                this.deleteFailure(response);
            }
        },

        deleteFailure (json) {
            console.log(json);
            this.$notify(json.errors, 'Error',
                {
                    type      : 'warning',
                    duration  : 3000,
                    permanent : false
                }
            );
        },

        setIsActive (user, status) {
            user.loading = true;
            const that = this;

            const permission = this.checkPermission(status);

            if (!permission) {
                alert('Don\'t have permission for this Action..!');
                return;
            }

            const model = {
                user   : user.id,
                status : status
            };

            axios.form(urls.userList.statusUpdate, model).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    user.loading = false;
                    user.is_active = status;
                    that.$notify(json.message, 'Success', { type : 'success' });
                    that.$refs.table.refreshTable();
                } else {
                    user.loading = false;
                    user.is_active = !status;
                    that.$notify(json.message, 'Warning', { type : 'warning' });
                }
            }).catch(function (exception) {
                that.$notify('Server Error', 'Error', { type : 'danger' });
                console.log('exception occurred : ', exception);
                user.is_active = !status;
            });
        },

        checkPermission (status) {
            if (status === true) {
                if (this.currentUser.is_superuser) {
                    return true;
                } else {
                    return this.currentUser.permission.can_approve_bank_admin;
                }
            } else {
                if (this.currentUser.is_superuser) {
                    return true;
                } else {
                    return this.currentUser.permission.can_reject_bank_admin;
                }
            }
        }
    }

};
</script>

<style scoped lang="scss">
.filter-grid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;

    @media (min-width: 992px) {
        grid-template-columns:2fr 1fr repeat(2, 2fr) 2fr 2fr;
    }

}

.line-right {
    position: absolute;
    right: 0;
    top: 0;
    width: 2px;
    height: 60%;
    background-color: rgba(128, 128, 128, 0.2);
}
</style>
